import React, { useState, useEffect } from 'react'
import "./assets/css/App.css";
import { TopBar } from "./components/TopBar";
import Footer from "./components/Footer";
import Form from "./components/Form";

function App() {

  // Data configurable desde el landing-config.json
  const [data, setData] = useState(null);

  // Metodo para obtener la data de landing-config.json
  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }

  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      {data !== null ? (
        <div className="fullPage">

          <div className="top-bar-position">
            <TopBar/>
          </div>

          <div className="form-position">
            <Form/>
          </div>

          <div className="footer-position">
            <Footer/>
          </div>
        </div>

      ):(<></>)}
    </div>
  )
}

export default App