import React from 'react'
import "../assets/css/footer.css";
import logo from "../assets/img/logo_footer.png";


const Footer = () => {
  return (
    <div className='footer'>
      <div className="img-container-footer">
        <a href="#root">
          <img src={logo} alt="logo" />
        </a>
      </div>

    </div>
  )
}

export default Footer
